import React from 'react'
import Seo from '../components/seo/seo'
import Layout from '../components/layout/Layout'
import { Link, graphql } from 'gatsby'
import moment from 'moment'
const config = require('../../gatsby-config')

const BlogPost = ({ data }) => {
  const apiUrl = config.siteMetadata.apiUrl
  const blogBasePath = `${config.siteMetadata.siteUrl}${config.siteMetadata.siteBlogPath}/`
  const blogPost = data?.blogPost || {}
  if (
    blogPost?.relatedPosts?.length === 1 &&
    blogPost?.relatedPosts?.[0]?.id === null
  ) {
    const post = blogPost?.relatedPosts?.[0]
    const updatedPosts = []

    if (post._0) updatedPosts.push(post._0)
    if (post._1) updatedPosts.push(post._1)
    if (post._2) updatedPosts.push(post._2)

    blogPost.relatedPosts = updatedPosts?.length > 0 ? updatedPosts : []
  }

  return (
    <>
      <Seo
        title={`${blogPost?.title} - immence`}
        description={`${
          blogPost?.description
            ? blogPost?.description
            : "We're always learning. Sometimes we write about it."
        }`}
        url={`${blogBasePath}${data?.slug}`}
        ogImage={`${apiUrl}${blogPost?.coverImage}`}
      />
      <Layout>
        <div className="sectionPadding">
          <div className="blogDetail">
            <div className="container">
              <h1
                className="blogmainTitle"
                dangerouslySetInnerHTML={{ __html: blogPost?.title }}
              />
              <div className="publishedInfo">
                <p>
                  By{' '}
                  <a href className="bluegray">
                    <strong>{blogPost?.author?.name}</strong>
                  </a>
                </p>
                <p>
                  <span className="bull">•</span> Published in{' '}
                  <a className="bluegray">
                    <strong>{`${blogPost?.blogCategoryName}`}</strong>
                  </a>
                </p>
                <p>
                  <span className="bull">•</span>
                  {blogPost?.publishDate
                    ? moment(blogPost?.publishDate).format('MMM DD, YYYY')
                    : ''}
                </p>
                <p className="redText">
                  <span className="bull">•</span>
                  <strong>
                    {blogPost?.readingTime
                      ? `${blogPost?.readingTime}  mins read`
                      : ``}
                  </strong>
                </p>
              </div>
              <div className="row">
                <div className="col-lg-8">
                  <div className="aboutBlog">
                    <img
                      src={`${apiUrl}${blogPost?.coverImage}`}
                      alt="blog"
                      className="img-fluid"
                    />
                    <div className="contentBox">
                      <div
                        className="mrgBox"
                        dangerouslySetInnerHTML={{ __html: blogPost?.content }}
                      />
                    </div>
                    <div className="tagPadd">
                      <div className="tagSection">
                        <div className="tagBox">
                          <h2>Tags</h2>
                          <ul>
                            {blogPost?.blogTagName?.map((tag) => (
                              <li>
                                <a href>#{tag}</a>
                              </li>
                            ))}
                          </ul>
                        </div>
                        {/* <div className="shareSec">
                          <h2>Share</h2>
                          <ul>
                            <li>
                              <a
                                href="https://www.facebook.com/goimmence"
                                target="_blank"
                                rel="noreferrer"
                                className="facebook"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 512 512"
                                >
                                  <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" />
                                </svg>
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://twitter.com/goimmence"
                                target="_blank"
                                rel="noreferrer"
                                className="twitter"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 512 512"
                                >
                                  <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
                                </svg>
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://www.linkedin.com/company/goimmence/"
                                target="_blank"
                                rel="noreferrer"
                                className="linkedin"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 448 512"
                                >
                                  <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
                                </svg>
                              </a>
                            </li>
                          </ul>
                        </div> */}
                      </div>
                    </div>
                    <div className="prevNextsec">
                      {blogPost?.previousArticle?.title && (
                        <div className="prev">
                          <p>Previous Article</p>
                          <Link
                            to={`${blogBasePath}${blogPost?.previousArticle?.slug}`}
                            dangerouslySetInnerHTML={{
                              __html: blogPost?.previousArticle?.title,
                            }}
                          />
                        </div>
                      )}
                      {blogPost?.nextArticle?.title && (
                        <div className="prev">
                          <p>Next Article</p>
                          <Link
                            to={`${blogBasePath}${blogPost?.nextArticle?.slug}`}
                            dangerouslySetInnerHTML={{
                              __html: blogPost?.nextArticle?.title,
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="rightBlogsec">
                    <div className="profileInfo">
                      <div className="imgSec">
                        <img
                          src={`${apiUrl}${blogPost?.author?.avatar}`}
                          alt={`author-name-${blogPost?.author?.name}`}
                          className="img-fluid userImg"
                        />
                      </div>
                      <p className="name">{blogPost?.author?.name}</p>
                      <p className="name-2">{blogPost?.author?.designation}</p>
                      <ul className="socialMediasec">
                        {blogPost?.author?.socialMediaProfile?.linkedIn && (
                          <li>
                            <a
                              href={
                                blogPost?.author?.socialMediaProfile?.linkedIn
                              }
                              className="globe"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1em"
                                height="1em"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill="currentColor"
                                  d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2zm-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93zM6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37z"
                                />
                              </svg>
                            </a>
                          </li>
                        )}
                        {blogPost?.author?.socialMediaProfile?.instagram && (
                          <li>
                            <a
                              href={
                                blogPost?.author?.socialMediaProfile?.instagram
                              }
                              className="instagram"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1em"
                                height="1em"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill="currentColor"
                                  d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4zm9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8A1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3"
                                />
                              </svg>
                            </a>
                          </li>
                        )}
                        {blogPost?.author?.socialMediaProfile?.twitter && (
                          <li>
                            <a
                              href={
                                blogPost?.author?.socialMediaProfile?.twitter
                              }
                              target="_blank"
                              rel="noreferrer"
                              className="twitter"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1em"
                                height="1em"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill="currentColor"
                                  d="M18.205 2.25h3.308l-7.227 8.26l8.502 11.24H16.13l-5.214-6.817L4.95 21.75H1.64l7.73-8.835L1.215 2.25H8.04l4.713 6.231zm-1.161 17.52h1.833L7.045 4.126H5.078z"
                                />
                              </svg>
                            </a>
                          </li>
                        )}
                        {blogPost?.author?.socialMediaProfile?.facebook && (
                          <li>
                            <a
                              href={
                                blogPost?.author?.socialMediaProfile?.facebook
                              }
                              className="globe"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1em"
                                height="1em"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill="currentColor"
                                  d="M22 12c0-5.52-4.48-10-10-10S2 6.48 2 12c0 4.84 3.44 8.87 8 9.8V15H8v-3h2V9.5C10 7.57 11.57 6 13.5 6H16v3h-2c-.55 0-1 .45-1 1v2h3v3h-3v6.95c5.05-.5 9-4.76 9-9.95"
                                />
                              </svg>
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                    <div className="postSec">
                      {blogPost?.relatedPosts?.length > 0 && (
                        <h3 className="relatedPost">Related Posts</h3>
                      )}
                      {blogPost?.relatedPosts?.map((data, i) => {
                        return (
                          <div className="postBox" key={`topThreeBlog-${i}`}>
                            <Link to={`${blogBasePath}${data?.slug}`}>
                              <img
                                src={`${apiUrl}${data?.coverImage}`}
                                alt="mobile_blog"
                                className="img-fluid"
                              />
                              <div className="paddBox">
                                <h5
                                  className="postTitle"
                                  dangerouslySetInnerHTML={{
                                    __html: data?.title,
                                  }}
                                />
                                <div className="postinfo">
                                  <div>
                                    <p
                                      className="authorName"
                                      dangerouslySetInnerHTML={{
                                        __html: data?.authorName,
                                      }}
                                    />
                                  </div>
                                  <div className="dateTime">
                                    <p className="date">
                                      {data?.publishDate
                                        ? moment(data?.publishDate).format(
                                            'MMM DD, YYYY'
                                          )
                                        : ''}
                                    </p>
                                    <div className="postTime">
                                      <span className="bull">•</span>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512"
                                      >
                                        <path d="M232 120C232 106.7 242.7 96 256 96C269.3 96 280 106.7 280 120V243.2L365.3 300C376.3 307.4 379.3 322.3 371.1 333.3C364.6 344.3 349.7 347.3 338.7 339.1L242.7 275.1C236 271.5 232 264 232 255.1L232 120zM256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0zM48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48C141.1 48 48 141.1 48 256z" />
                                      </svg>
                                      <p>
                                        {data?.readingTime
                                          ? `${data?.readingTime} min`
                                          : ``}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query ($slug: String!) {
    blogPost(slug: { eq: $slug }) {
      id
      title
      slug
      content
      description
      coverImage
      blogCategoryName
      blogTagName
      readingTime
      metaTitle
      metaDescription
      metaKeywords
      publishDate
      previousArticle {
        id
        title
        slug
      }
      nextArticle {
        id
        title
        slug
      }
      author {
        name
        avatar
        designation
        socialMediaProfile {
          instagram
          linkedIn
          facebook
          twitter
        }
      }
      relatedPosts {
        authorName
        coverImage
        id
        publishDate
        readingTime
        slug
        title
        _0 {
          authorName
          coverImage
          id
          publishDate
          readingTime
          slug
          title
        }
        _1 {
          authorName
          coverImage
          id
          publishDate
          readingTime
          slug
          title
        }
        _2 {
          authorName
          id
          coverImage
          publishDate
          readingTime
          slug
          title
        }
      }
    }
  }
`

export default BlogPost
